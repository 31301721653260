import React from "react"
import { Helmet } from "react-helmet"
import { Layout } from "../layouts/default"

const NotFoundPage = () => (
  <Layout>
    <Helmet>
      <title>Micropore Technologies - Page Not Found</title>
      <meta
        name="description"
        content="The page you are looking for could not be found."
      />
    </Helmet>

    <h1>404: Not Found</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
)

export default NotFoundPage
